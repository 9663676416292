import { AppErrorPage } from '@/components/AppErrorPage/AppErrorPage'
import '@/index.css'
import '@alma/react-components/style.css'
import React from 'react'
import ReactDOM from 'react-dom'

// We need to ignore those errors so stonly can be displayed correctly
const errorToIgnore = [
  'ResizeObserver loop limit exceeded',
  'ResizeObserver loop completed with undelivered notifications.',
]

export const isErrorToIgnore = (err: ErrorEvent) => errorToIgnore.includes(err.message)

export const isFromOurCode = (err: ErrorEvent) => err.filename.startsWith(window.origin)

const handleGlobalErrors = (err: ErrorEvent) => {
  if (!isFromOurCode(err) || isErrorToIgnore(err)) {
    return
  }

  document.getElementById('root')?.remove()

  ReactDOM.render(<AppErrorPage />, document.getElementById('error'))
}

window.addEventListener('error', handleGlobalErrors)
