import s from '@/components/AppErrorPage/AppErrorPage.module.css'
import { Button, Icon } from '@alma/react-components'
import React, { FunctionComponent, ReactNode } from 'react'

export interface AppErrorPageProps {
  title?: ReactNode
  buttonLabel?: ReactNode
}

export const AppErrorPage: FunctionComponent<AppErrorPageProps> = ({
  title = 'Error',
  buttonLabel = 'Refresh',
  children,
}) => (
  <div className={s.container}>
    <Icon icon="warning" className={s.warning} color="var(--alma-red)" />
    <div>
      <div className={s.title}>{title}</div>
      <div className={s.text}>{children}</div>
    </div>
    <Button color="primary" onClick={() => window.location.reload()}>
      {buttonLabel}
    </Button>
  </div>
)
